<template>
  <v-app>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="8"
      >
        <base-material-card
          icon="mdi-account"
          :color="$vuetify.theme.dark ? 'orange' : 'primary'"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              URL
            </div>
          </template>
          <v-form>
            <v-card>
              <div>
                <div class="d-flex flex-no-wrap justify-start align-start">
                  <div class="d-flex flex-column align-start flex-grow-1">
                    <v-form style="width: 100%">
                      <v-text-field
                        v-model="play.data.url"
                        label="URL"
                        placeholder="'Masukan Url"
                        clearable
                      />
                    </v-form>
                  </div>
                  <div class="d-flex align-center align-self-sm-center">
                    <v-tooltip
                      bottom
                      color="blue"
                    >
                      <!--  -->
                      <template v-slot:activator="{ on, attrs }">
                        <!--  -->
                        <v-btn
                          icon
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          @click="editURL"
                        >
                          <v-icon>
                            mdi-send
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </v-card>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'EditPlay',
    data: () => ({
      play: {
        meta: {},
        data: {},
      },
    }),
    mounted () {
      this.getPlay()
    },
    methods: {
      editURL () {
        axios
          .patch('v1/media/' + this.$route.params.id + '/video', {
            url: this.play.data.url,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/play' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              this.$toast.error('error', {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      setPlay (data) {
        this.play = data
      },
      getPlay () {
        axios.get('/v1/media/' + this.$route.params.id).then(response => {
          this.setPlay(response.data)
        })
      },
    },
  }
</script>

<style></style>
